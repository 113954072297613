.recorder-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75vh;
    &__footer{
        position: absolute;
        bottom: 0;
        width: 100%;
    }

}