.shortcuts{
    margin: 1em auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;

    &__item{
        display: flex;
        align-items: center;

        margin-left: 2em;
        &:first-child{
            margin-left: 0;
        }

        &__button{
            background: none;
            border: none;
            outline: none;
            padding: 0;
        }
    }
}