.navbar__hr {
  width: 0;
  transition: width 1s ease-in-out;
  &--active {
    width: 90%;
  }

  border-width: 1px 0;
}

.nav-hr {
  margin: 0 auto 10px auto;
  height: 1px;
  width: 90%;
  //   max-width: 600px;

  &__line {
    height: 100%;
    width: 0;
    transition: width 0.6s ease-in-out;

    background-color: gray;
    &--active {
      width: 100%;
    }
  }
}

.navbar {
  margin: 0 auto;
  display: flex;
  width: 80%;

  &--links {
    width: 90%;
  }
  &--buttons {
    width: 90%;
  }
  &__back {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
    background: none;
    border: none;
    outline: none;
  }

  &__links {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
    display: flex;
    ul {
      margin: 0;
      padding: 0;
      align-self: center;
      display: flex;
    }
    li {
      margin: 0;
      display: flex;
    }

    &__link {
      outline: none;
      padding: 1.2em 1.2em;
      background-color: transparent;
      transition: all 0.4s ease-in-out;
      color: gray;
      text-decoration: none;

      &::before,
      &::after {
        transition: opacity 0.2s ease-in-out;
      }
      &::before {
        content: "> ";
        opacity: 0;
      }
      &::after {
        content: " <";
        opacity: 0;
      }

      &--active {
        &::before,
        &::after {
          opacity: 1;
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--links &__back {
    opacity: 0;
    visibility: hidden;
  }

  &--buttons &__links {
    opacity: 0;
    visibility: hidden;
  }
}
