.button-circle {
  position: relative;
  margin: 5px;
  border-radius: 50%;
  border: 1px solid gray;
  height: 70px;
  width: 70px;
  background-color: white;
  transition: opacity 200ms;
  font-size: 1rem;

  &:hover{
    background-color: #00000006;
  }

  & .fa-play {
    margin-left: 4px;
  }

  &:focus{
      outline: 1px dashed #d2d2d2;
  }

  &:disabled{
    opacity: 0.5;
  }
}

.button-circle__icon {
  opacity: 1;
  transition: all 300ms;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &--hidden {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(90deg);
  }
}
