.basic-player{

    margin: 0 auto;

    &__bookmark-slider{
        -webkit-appearance: none;
        // width: 50%;
        // height: 1px;
        // background: #00000012;
        outline: none;
        // opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
    
        &--hidden{
            visibility: hidden;
        }
    
    
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: gray;
            cursor: pointer;
          }
    
          &:hover::-webkit-slider-thumb{
            width: 10px;
            height: 10px;
          }
    
    }
}


.basic-player__position-slider{
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    background: #00000012;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 4px;
        height: 25px;
        background: gray;
        cursor: pointer;
      }

    // &::-moz-range-thumb {
    //     width: 25px;
    //     height: 25px;
    //     background: #4CAF50;
    //     cursor: pointer;
    //   }
}

.basic-player{
    border-radius: 20px;
    overflow: hidden;
}
  
