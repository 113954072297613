.swiper-container {
  margin: 10px;
  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    border: 1px solid gray;
    border-radius: 20px;
    padding: 20px;
    // height: 12rem;
    // width: 20rem;
    color: gray;

    &__text {
      color: gray;
      text-align: center;
      margin-top: 0px;
      font-size: 2.5rem;
      margin-bottom: 2px;
    }

    &__controls {
      display: flex;
      justify-content: center;

      &__source {
        margin-right: 30px;
        flex-direction: column;
        display: flex;
        text-align: center;
        &__label {
          margin-top: 5px;
        }
      }
      &__record {
        &__label {
          display: block;
          text-align: center;
          margin-top: 5px;
        }
      }
    }
  }
}

.swiper-button-next:after,
.swiper-button-prev:after {
  padding: 10px;
  background-color: #ffffffd9;
  color: gray;
}
